<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name"/>
    <v-row>
      <v-col>
        <base-table ref="base_table" :page="page" @create="create" @edit="edit" @download="exportDocuments"/>
      </v-col>
    </v-row>
    <create-document modal_name="create_document" ref="create_document" @edit="goToAsset" @refresh="reload"/>
    <document-preview modal_name="document_preview" ref="document_preview" @create="createRevision" @edit="goToAsset" @refresh="reload"/>
  </v-container>
</template>

<script>
import BaseTable from "../../components/commonComponents/BaseTable";
import CreateDocument from "./Create";
import DocumentPreview from "../../components/commonComponents/DocumentPreview";
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
import Pusher from "pusher-js";

export default {
  components: {
    BaseTable,
    BaseBreadcrumb,
    CreateDocument,
    DocumentPreview,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("documents"),
        name: "documents",
        model: "document",
      },
    };
  },
  created() {
    let pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
      cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
      encrypted: true,
    });
    Pusher.logToConsole = false;

    pusher.subscribe("exportDocuments");
    pusher.bind("export-documents", (data) => {
      this.$toasted.info('<a style="color:white" href="' + data.response.link + '" target="_blank"><strong>Download exported documents</strong></a>',
          {timeout: 999999999, dismissable: true, multiLine: true, queueable: false, showClose: true, closeText: "Close", closeIcon: "close"}
      );
    });
  },
  methods: {
    create() {
      this.$refs.create_document.load();
    },
    edit(item) {
      this.$refs.document_preview.load(item.id);
    },
    reload() {
      this.$refs.base_table.run();
    },
    goToAsset(asset_id) {
      this.$router.push({name: "Platforms", params: { asset_id: asset_id }});
    },
    createRevision(document) {
      this.$refs.create_document.load(document, null, null);
    },
    exportDocuments(selected) {
      var document_ids = [];
      selected.forEach((document) => {
        document_ids.push(document.id);
      });
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/documents/get-file-ids", document_ids)
          .then((response) => {
            this.$http
                .post(this.$store.getters.appUrl + "v2/export/documents", response.data)
                .then((response) => {
                  this.loading = false;
                  if (response.status == 200) {
                    this.$toasted.success(this.$store.getters.translate("export_in_progress"));
                  } else {
                    var errorMessage = response.data.message;
                    var errors = Object.keys(response.data.message);
                    this.$toasted.error([errorMessage[errors[0]]]);
                  }
                }).catch((error) => {
                  if (this.$store.getters.isLoggedIn) {
                    this.$toasted.error(error);
                  }
                  this.loading = false;
                });
          }).catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
  },
};
</script>
